<template>
  <div class="single-item">
    <div class="single-item-input-texts">
        <div class="he">
            <el-input style="width:50%;" :input-style="input_style"  v-if="!is_sales_print" v-model="values.he_name" maxlength="35" placeholder="שם המוצר בעברית" />
            <h1 v-if="is_sales_print">{{values.he_name}}</h1>
        </div>
        <div class="ru">
            <el-input :input-style="input_style" style="width:50%;" v-if="!is_sales_print" v-model="values.ru_name" maxlength="38" placeholder="שם המוצר בלועזית" />
            <h1 v-if="is_sales_print">{{values.ru_name}}</h1>
        </div>
        <i v-if="!item_is_choosen" @click="show_items_list=true" class="material-icons float-icon add-icon">add</i>
        <i v-if="item_is_choosen" @click="handle_clean" class="material-icons float-icon clean-icon">cleaning_services</i>
        <div class="logo">
            <img src="../../../../assets/images/rosman-market-new.png">
        </div>
    </div>
    <div class="single-item-contents">
        <div class="col-2 col">
            <el-input :input-style="input_style" type="number" v-if="!is_sales_print" v-model="values.first_number" placeholder="הזן מספר" />
            <h1 v-if="is_sales_print"> {{values.first_number}} {{values.size}} <span v-if="values.size=='יח'">'</span></h1>
        </div>
        <div class="col-1 col">
            <h1>ב-</h1>
        </div>
        <div class="col-2 col">
            <el-input :input-style="input_style" type="number" v-if="!is_sales_print" v-model="values.price" placeholder="הזן מספר" />
            <h1 v-if="is_sales_print">{{values.price}} <small>&#8362;</small></h1>
        </div>
    </div>
    <div class="single-item-footer">
        <div class="barcodes">
            <h1 data-fixed>
                <i v-if="barcode_are_choosen.length==0" @click="show_items_list=true; is_barcodes=true;" class="material-icons add-icon">add</i>
                <i v-else @click="barcode_are_choosen=[]" class="material-icons clean-icon">cleaning_services</i>
                ברקוד: 
            </h1>
            <h1 data-slice v-if="barcode_are_choosen.length>0">{{barcode_are_choosen.slice(0,8).join(", ")}}</h1>
            <h1 v-else>{{values.barcode}}</h1>
        </div>
        <div class="grams">
            <small v-if="price_per_100_gram">מחיר ל100 גרם: {{price_per_100_gram}} &#8362;</small>
        </div>
    </div>
  </div>
  <div v-if="show_items_list" class="choos-item slide-in-top">
    <ShilutDb 
        :is_choose="true" 
        @item_choosen="handle_item_choosen" 
        :is_barcodes="is_barcodes" 
        @back="show_items_list=false; is_barcodes = false;"
        @barcodes="handle_choosen_barcodes"
        />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {is_sales_print} from '../Scripts/scripts'
import ShilutDb from '../ShilutDb.vue'
import { computed } from '@vue/runtime-core'

export default {
    components:{ShilutDb},
    setup(){
        
        const is_barcodes = ref(false)

        const barcode_are_choosen = ref([])

        const item_is_choosen = ref(false)

        const show_items_list = ref(false)

        const values = ref({
            first_number:'1',
            second_number:'',
            barcode:'',
            he_name:'',
            ru_name:'',
            size:'',
            volume:'',
            price:'',
        })

        const price_per_100_gram = ref(computed(()=>{
            if(values.value.volume && Number(values.value.volume)>=100 && values.value.price && values.value.size == 'יח'){
                return ((Number(values.value.price) / Number(values.value.first_number))/(Number(values.value.volume)/100)).toFixed(2)
            }
            return false
        }))

        const handle_clean = () => {
           values.value = {
                first_number:'1',
                second_number:'',
                barcode:'',
                he_name:'',
                ru_name:'',
                size:'',
                volume:'',
                price:'',
            }
           item_is_choosen.value = false
        }

        const handle_item_choosen = (item) => {
            values.value = item
            show_items_list.value = false
            item_is_choosen.value = true
        }

        const handle_choosen_barcodes = (barcodes) =>{
            is_barcodes.value = false
            barcode_are_choosen.value = barcodes
            show_items_list.value = false
        }

        const input_style = ref({
            'font-size':'40px',
            'color':'black',
            'margin-top':'10px',
            'text-align': 'center',
            'border': '2px solid black',
            'border-left':'0',
            'border-right':'0',
            'border-top':'0',
        })

        return{
            handle_item_choosen,
            handle_clean,
            handle_choosen_barcodes,
            values,
            is_sales_print,
            show_items_list,
            item_is_choosen,
            price_per_100_gram,
            input_style,
            is_barcodes,
            barcode_are_choosen,
        }
    }
}
</script>

<style scoped>
    .single-item{
        width: 100%;
        height: 100%;
    }

    .single-item-input-texts{
        position: relative;
        width: 100%;
        height: 30%;
    }
    .float-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
    }
    .add-icon,.clean-icon{
        color: var(--success);
        cursor: pointer;
        font-size: 50px;
        user-select: none;
    }
    .clean-icon{
        color: var(--danger);
    }
    .single-item-input-texts .he,.single-item-input-texts .ru{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        text-align: center;
    }
    .single-item-input-texts .ru{
        font-size: 18px;
        
    }

    .logo{
        position: absolute;
        width: 100px;
        height: 100%;
        bottom: 0;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo img{
        max-width: 100%;
        max-height: 100%;
    }

    .single-item-contents{
        width: 100%;
        height: 50%;
        display: flex;
        font-size: 40px;
    }
    .col{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .col-2{
        width: 45%;
        height: 100%;
    }
    .col-1{
        width: 10%;
        height: 100%;
    }
    .col.small{
        font-size: unset; 
        text-align: center;
    }
    .single-item-footer{
        position: relative;
        width: 100%;
        height: 20%;
        display: flex;
    }
    .single-item-footer .barcodes,.single-item-footer .grams{
        width: 50%;
        height: 100%;
    }
    .single-item-footer .grams{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }
    .single-item-footer .barcodes{
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
   
    [data-slice]{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
    }
    [data-fixed]{
        display: flex;
        align-items: center;
    }

    .choos-item{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--main);
        z-index: 1000;
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>